import React, { useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as DefaultSortIcon } from "../../assets/icons/generic/tableSortIcons/SortDown.svg";
import { ReactComponent as GoogleAdIcon } from "../../assets/icons/linkTypeAdsIcons/Google.svg";
import { ReactComponent as MetaAdIcon } from "../../assets/icons/linkTypeAdsIcons/MetaPlatforms.svg";
import { ReactComponent as LinkedinAdIcon } from "../../assets/icons/linkTypeAdsIcons/LinkedIn.svg";
import { ReactComponent as TikTokAdIcon } from "../../assets/icons/linkTypeAdsIcons/TikTok.svg";
import { ReactComponent as GrovsAdIcon } from "../../assets/icons/linkTypeAdsIcons/Grovs.svg";

import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useDismiss,
  useRole,
  useClick,
  useInteractions,
  FloatingFocusManager,
  useId,
} from "@floating-ui/react";
import {
  GOOGLE,
  LINKEDIN,
  META,
  QUICK_LINK,
  TIKTOK,
} from "../../constants/OptionsConstants";

const CustomSelectItem = ({ label, value, selectedMetrics, onClick }) => {
  return (
    <CustomListElementContainer onClick={onClick}>
      {value === GOOGLE && <GoogleAdIcon />}
      {value === META && <MetaAdIcon />}
      {value === LINKEDIN && <LinkedinAdIcon />}
      {value === TIKTOK && <TikTokAdIcon />}
      {value === QUICK_LINK && <GrovsAdIcon />}
      <p>{label}</p>
      <input readOnly checked={selectedMetrics} type="checkbox" />
    </CustomListElementContainer>
  );
};

const AdsPlatformFilter = ({
  filterOptions,
  selectedMetrics,
  setSelectedMetrics,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(10),
      flip({ fallbackAxisSideDirection: "end" }),
      shift(),
    ],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  const headingId = useId();
  const metricsList = filterOptions;

  const handleSelectMetrics = (metric) => {
    setSelectedMetrics(metric);
    setIsOpen(false)
  };

  const displaySelectedFilter = (filter) => {

    return (
      <SelectedFilterElement>
        {filter === GOOGLE && <GoogleAdIcon />}
        {filter === META && <MetaAdIcon />}
        {filter === LINKEDIN && <LinkedinAdIcon />}
        {filter === TIKTOK && <TikTokAdIcon />}
        {filter === GOOGLE && <p>Google</p>}
        {filter === META && <p>Meta</p>}
        {filter === LINKEDIN && <p>Linkedin</p>}
        {filter === TIKTOK && <p>TikTok</p>}
        {filter === QUICK_LINK && <p>Quick links</p>}
        {filter === null && <p>All platforms</p>}
        <DefaultSortIcon style={{ marginLeft: "auto" }} />
      </SelectedFilterElement>
    )
  }






  return (
    <Container>
      <FilterMenu
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {displaySelectedFilter(selectedMetrics)}
      </FilterMenu>

      {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <MetricsList
            ref={refs.setFloating}
            style={floatingStyles}
            aria-labelledby={headingId}
            {...getFloatingProps()}
          >
            {metricsList?.map((metric, index) => (
              <CustomSelectItem
                key={index}
                label={metric.label}
                value={metric.value}
                selectedMetrics={selectedMetrics === metric.value}
                onClick={() => handleSelectMetrics(metric.value)}
              />
            ))}
          </MetricsList>
        </FloatingFocusManager>
      )}
    </Container>
  );
};

export default AdsPlatformFilter;
const Container = styled.div`
  position: relative;
`;
const MetricsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px 10px;
  border-radius: 5px;
  border: 1px solid ${lightThemeColors.primaryColor};
  background: ${lightThemeColors.background};
  width: 100%;
  min-height: 100px;
  min-width: 180px;
  z-index: 5;
`;

const FilterMenu = styled.div`
display:flex;
align-items:center;
border:none;
cursor:pointer;
  background: ${lightThemeColors.menuBackground};
  color: ${lightThemeColors.darkColorFaded};
  padding: 8px;
  border-radius: 5px;
  width: 100%;
min-width:120px;
svg{
    color:${lightThemeColors.primaryColor};
    height:16px;
} 
`;

const CustomListElementContainer = styled.div`

  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${lightThemeColors.darkColorFaded};
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  input {
    margin-left: auto;
  }
  svg {
    margin-right: 5px;
    height: 25px;
    width: 25px;
  }
`;

const SelectedFilterElement = styled.div`
display:flex;
align-items:center;
gap:10px;
width:100%;
p{
font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: ${lightThemeColors.darkColor};
}
svg{
    max-height: 16px;
    max-width: 16px;
}
`