import { DELETE, GET, PATCH, POST } from "../API";


export const signInAPICall = (email, password, otpCode, onSuccess, onError) => {
    const data = {
        grant_type: "password",
        email: email,
        password: password,
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
    };

    if (otpCode != "") {
        data.otp_code = otpCode
    }

    POST("/oauth/token", data, onSuccess, onError);
};

export const logoutAPICall = (token, onSuccess, onError) => {
    const data = {
        token: token,
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
    };

    POST("/oauth/revoke", data, onSuccess, onError);
};

export const acceptInviteAPICall = (
    invitationToken,
    name,
    password,
    onSuccess,
    onError
) => {
    const data = {
        name: name,
        invitation_token: invitationToken,
        password: password,
        client_id: process.env.REACT_APP_CLIENT_ID,
    };

    POST(
        process.env.REACT_APP_API_PATH + "/users/accept_invite",
        data,
        onSuccess,
        onError
    );
};


export const createAccountAPICall = (
    email,
    password,
    name,
    onSuccess,
    onError
) => {
    const data = {
        email: email,
        password: password,
        name: name,
        client_id: process.env.REACT_APP_CLIENT_ID,
    };

    POST(
        process.env.REACT_APP_API_PATH + "/users/create",
        data,
        onSuccess,
        onError
    );
};

export const resetPasswordAPICall = (email, onSuccess, onError) => {
    const data = {
        email: email,
    };

    POST(
        process.env.REACT_APP_API_PATH + "/users/reset_password",
        data,
        onSuccess,
        onError
    );
};

export const changePasswordAPICall = (token, password, onSuccess, onError) => {
    const data = {
        new_password: password,
        reset_token: token,
    };

    POST(
        process.env.REACT_APP_API_PATH + "/users/change_password",
        data,
        onSuccess,
        onError
    );
};

export const currentUserAPICall = (onSuccess, onError) => {
    GET(
        process.env.REACT_APP_API_PATH + "/users/current_user_details",
        onSuccess,
        onError
    );
};

export const enable2FAAPICall = (enable, otpCode, onSuccess, onError) => {
    const data = {
        enable_2fa: enable,
        otp_code: otpCode
    }
    POST(
        process.env.REACT_APP_API_PATH + "/users/set_2fa_enabled",
        data,
        onSuccess,
        onError
    );
};

export const isOtpEnabledAPICall = (email, onSuccess, onError) => {
    const data = {
        email: email
    }
    POST(
        process.env.REACT_APP_API_PATH + "/users/otp_enabled",
        data,
        onSuccess,
        onError
    );
};

export const getOTPQrcodeAPICall = (onSuccess, onError) => {
    GET(
        process.env.REACT_APP_API_PATH + "/users/otp_qr",
        onSuccess,
        onError
    );
};

export const removeUserAPICAll = (email, onSuccess, onError) => {
    DELETE(
        process.env.REACT_APP_API_PATH + `/users/remove_user?email=${email}`,
        onSuccess,
        onError
    );
};
export const editUserAPICall = (data, onSuccess, onError) => {

    PATCH(
        process.env.REACT_APP_API_PATH + `/users/edit_user`,
        data,
        onSuccess,
        onError
    );
};


