import { useEffect, useRef, useState } from "react";
import LoaderComponent from "./components/Generic/LoaderComponent";
import SideBarMenu from "./components/SideNav/SideBarMenu";
import {
  Outlet,
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import styled from "styled-components";
import {
  ANDROID,
  DESKTOP,
  IOS,
  PRODUCTION,
  TEST,
  WEB,
} from "./constants/OptionsConstants";
import CreateNewLinkModal from "./components/createNewLink/CreateNewLinkModal";
import CreateNewProjectModal from "./components/createNewProject/CreateNewProjectModal";
import { useInstanceContext } from "./context/useInstanceContext";
import { showGenericError } from "./helper/Notifications";
import CreateFirstProjectModal from "./components/createNewProject/CreateFirstProjectModal";
import { lastIndexOf } from "lodash";
import TestModeBanner from "./components/Generic/TestModeBanner";
import { AnimatePresence, motion } from "framer-motion";
import { useConfigurationContext } from "./context/useConfigurationContext";
import NoSdkBanner from "./components/Generic/NoSdkBanner";
import { usePaymentsContext } from "./context/usePaymentsContext";
import NoSubscriptionBanner from "./components/Generic/NoSubscriptionBanner";
import ContactSaleModal from "./components/subscriptionComponents/ContactSaleModal";
import { driver } from "driver.js";

import "driver.js/dist/driver.css";
import { scrollToElement, waitForElement } from "./helper/tourHelper";
import Joyride from 'react-joyride';


function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [projectType, setProjectType] = useState(PRODUCTION);
  const [addNewLinkModalOpen, setAddNewLinkModalOpen] = useState(false);
  const [createNewProjectModalOpen, setCreateNewProjectModalOpen] =
    useState(false);
  const [contactSaleModalOpen, setContactSaleModalOpen] = useState(false);
  const firstLoginRef = useRef(false);

  const {
    instances,
    selectedProject,
    getInstances,
    setSelectedProject,
    selectedInstance,
    setSelectedInstance,
    getInstanceUserRole,
    getProjectConfiguration,
  } = useInstanceContext();

  const { getSubscriptionDetails, getCurrentMau } = usePaymentsContext();
  // const [run, setRun] = useState(true);

  const navigate = useNavigate();

  const initializeFromParams = () => {
    let projectType = searchParams.get("env_type");
    if (projectType) {
      setProjectType(projectType);
    }
  };

  const handleGetInstances = () => {
    setLoading(true);
    getInstances(
      (response) => {
        const instances = response.data.instances;
        let sortedInstances = instances.sort((a, b) => a.updated_at < b.updated_at ? 1 : -1)
        handleInstanceResponse(sortedInstances);
        setLoading(false);
      },
      () => {
        showGenericError();
        setLoading(false);
      }
    );
  }

  const handleInstanceResponse = (instances) => {
    if (searchParams.get("instance_id")) {
      let found = instances.find(
        (item) => item.id == searchParams.get("instance_id")
      );
      if (found) {
        setSelectedInstance(found);
      }
    } else {
      if (instances.length > 0) {
        firstLoginRef.current = true;
        const latestInstanceCreated = instances[0];
        setSearchParams((params) => {
          params.set("instance_id", latestInstanceCreated.id);
          return params;
        });

        setSelectedInstance(latestInstanceCreated);
        setLoading(false);
      }
    }
  };

  const handleGetProjectConfig = (callBack) => {
    getProjectConfiguration(
      selectedInstance.id,
      (response) => {
        callBack(response);
      },
      () => {
        showGenericError();
      }
    );
  };

  const handleGeSubscriptionDetails = () => {
    setLoading(true);
    getSubscriptionDetails(
      selectedInstance.id,
      (response) => {
        setLoading(false);
      },
      (error) => {
        if (error.response?.data?.message !== "No active subscriptions") {
          showGenericError();
        }
        setLoading(false);
      }
    );
  };

  const handleGetCurrentMau = () => {
    setLoading(true);
    getCurrentMau(
      selectedInstance.id,
      (response) => {
        setLoading(false);
      },
      () => {
        showGenericError();
        setLoading(false);
      }
    );
  };



  // const driverObj = driver({
  //   showProgress: true,

  //   steps: [
  //     {
  //       element: "#developersTab",
  //       popover: {
  //         title: "1. Integrate the SDKs ",
  //         onNextClick: async () => {
  //           navigate({
  //             pathname: "/configuration",
  //             search: createSearchParams(searchParams).toString(),
  //           });
  //           await waitForElement("#redirectSectionHeader")
  //           driverObj.refresh();
  //           driverObj.moveNext();
  //         },

  //       },
  //     },
  //     {
  //       element: "#redirectSectionHeader",
  //       popover: {
  //         title: "2. Configure redirects",
  //         onNextClick: async () => {
  //           setSearchParams((params) => {
  //             params.set("redirectTour", true)
  //             return params
  //           })
  //           await waitForElement("#redirectFallbackSection")
  //           driverObj.moveNext();
  //         },
  //       }
  //     },
  //     {
  //       element: "#redirectFallbackSection",
  //       popover: {
  //         title: "2.1 Set a fallback URL",
  //       }
  //     },
  //     {
  //       element: "#redirectSection",
  //       popover: {
  //         title: "2.2 Set redirect rules",
  //         onNextClick: async () => {
  //           setSearchParams((params) => {
  //             params.set("socialMediaTour", true)
  //             return params
  //           })
  //           // await waitForElement("#socialMediaPreviewContainer");
  //           scrollToElement("#socialMediaPreviewContainer");
  //           await new Promise((resolve) => setTimeout(resolve, 300));
  //           driverObj.moveNext();
  //         },
  //       }
  //     },
  //     {
  //       element: "#socialMediaPreviewContainer",

  //       popover: {
  //         title: "2.3 Configure social media",

  //       }
  //     },
  //   ]

  // })

  // const steps = [
  //   {
  //     target: "#developersTab",
  //     title: "1. Integrate the SDKs",
  //     content: "Click here to start integrating the SDKs.",
  //   },
  //   {
  //     target: "#redirectSectionHeader",
  //     title: "2. Configure redirects",
  //     content: "This is where you configure redirects.",
  //   },
  //   {
  //     target: "#redirectFallbackSection",
  //     title: "2.1 Set a fallback URL",
  //     content: "Set a fallback URL here for unmatched redirects.",
  //   },
  //   {
  //     target: "#redirectSection",
  //     title: "2.2 Set redirect rules",
  //     content: "Define redirect rules in this section.",
  //   },
  //   {
  //     target: "#socialMediaPreviewContainer",
  //     title: "2.3 Configure social media",
  //     content: "Configure social media settings here.",
  //   },
  // ];

  // const handleJoyrideCallback = async (data) => {
  //   const { action, index, status, step } = data;
  //   console.log(data, "DATA")



  //   if (action === "next" && index === 0) {
  //     // Example: Navigate to "/configuration" after the first step
  //     navigate({
  //       pathname: "/configuration",
  //       search: createSearchParams(searchParams).toString(),
  //     });
  //     await waitForElement("#redirectSectionHeader")
  //   }

  //   if (action === "update" && index === 1) {
  //     setSearchParams((params) => {
  //       params.set("redirectTour", true)
  //       return params
  //     })
  //   }

  //   if (action === "update" && step.target === "#socialMediaPreviewContainer") {
  //     const element = document.querySelector(step.target);
  //     if (element) {
  //       element.scrollIntoView({
  //         behavior: "smooth",
  //         block: "start",
  //       });
  //     }
  //   }

  // };


  useEffect(() => {
    initializeFromParams();
    handleGetInstances()
    // driverObj.drive();
    const intervalId = setInterval(() => {
      handleGetInstances()
    }, 600000)
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!selectedInstance) {
      return;
    }

    if (projectType === TEST) {
      setSelectedProject(selectedInstance.test);
    } else {
      setSelectedProject(selectedInstance.production);
    }

    setSearchParams((prev) => {
      prev.set("env_type", projectType);
      return prev;
    });
  }, [projectType, selectedInstance]);

  useEffect(() => {
    if (!selectedInstance) {
      return;
    }

    getInstanceUserRole(
      selectedInstance.id,
      (response) => { },
      (error) => {
        console.log(error);
      }
    );

    handleGetProjectConfig((response) => {
      if (firstLoginRef.current) {
        firstLoginRef.current = false;
        const resp = response.data.configurations;
        let android = resp.find((item) => item.platform === ANDROID);
        let ios = resp.find((item) => item.platform === IOS);

        if (android?.configuration || ios?.configuration) {
          navigate({
            pathname: "/links",
            search: createSearchParams(searchParams).toString(),
          });
        } else {
          navigate({
            pathname: "/developers",
            search: createSearchParams(searchParams).toString(),
          });
        }
      }
      setLoading(false);
    });

    handleGeSubscriptionDetails();
    handleGetCurrentMau();
  }, [selectedInstance]);

  return (
    <LoaderComponent loading={loading}>
      {/* <Joyride
        steps={steps}
        run={run}
        continuous
        scrollToFirstStep
        showProgress
        showSkipButton
        callback={handleJoyrideCallback}
        styles={{
          options: {
            zIndex: 10000, // Ensure it's above other UI elements
          },
        }}
      /> */}
      <Container>
        <SideBarMenu
          setCreateNewProjectModalOpen={setCreateNewProjectModalOpen}
          createNewProjectModalOpen={createNewProjectModalOpen}
        />

        <OutletWrapper>
          <TestModeBanner projectType={projectType} />
          <NoSdkBanner />
          <NoSubscriptionBanner />
          <Outlet
            context={{
              projectType,
              setProjectType,
              loading,
              setLoading,
              addNewLinkModalOpen,
              setAddNewLinkModalOpen,
              createNewProjectModalOpen,
              setCreateNewProjectModalOpen,
              contactSaleModalOpen,
              setContactSaleModalOpen,
            }}
          />
        </OutletWrapper>
        {instances?.length == 0 && (
          <CreateFirstProjectModal setLoading={setLoading} />
        )}
        <AnimatePresence>
          {addNewLinkModalOpen && (
            <CreateNewLinkModal
              isMoldaOpen={addNewLinkModalOpen}
              setIsModalOpen={setAddNewLinkModalOpen}
            />
          )}
        </AnimatePresence>
        <AnimatePresence>
          {createNewProjectModalOpen && (
            <CreateNewProjectModal
              isMoldaOpen={createNewProjectModalOpen}
              setIsModalOpen={setCreateNewProjectModalOpen}
            />
          )}
        </AnimatePresence>
        <AnimatePresence>
          {contactSaleModalOpen && (
            <ContactSaleModal
              setLoading={setLoading}
              isMoldaOpen={contactSaleModalOpen}
              setIsModalOpen={setContactSaleModalOpen}
            />
          )}
        </AnimatePresence>
      </Container>
    </LoaderComponent>
  );
}

export default App;

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;
const AnimatedDiv = styled(motion.div)``;

const OutletWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
`;
