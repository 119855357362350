export const lightThemeColors = {
  darkColorFaded: "rgba(43, 58, 84, 1)",
  darkColorFullFaded: "rgba(19, 36, 65, 0.1)",
  darkColorFaded30: "rgba(19, 36, 65, 0.3)",
  darkColorFaded60: "rgba(19, 36, 65, 0.6)",
  darkColor: "rgba(19, 36, 65, 1)",
  primaryColor: "rgba(62, 146, 255, 1)",
  background: "rgba(255, 255, 255, 1)",
  menuBackground: "rgba(248, 248, 248, 1)",
  submenuBackground: "rgba(248, 248, 248, 1)",
  validGreen: "rgba(112, 203, 151, 1)",
  validGreenFaded: "rgba(112, 203, 151, 0.2)",
  menuBorder: "#E7E9EC",
  inputSubtitleColor: "rgba(128, 137, 152, 1)",
  inputLabelColor: "rgba(85, 158, 255, 1)",
  noSDKBanner: "#C65835",
  quotaColor: "rgba(228, 63, 62, 1) ",
  planCardBg: "rgba(246, 244, 242, 1)",
  linkTypeElementBg: "#F4F9FF",

  authentication: {
    inputBg: "rgba(19, 36, 65, 0.05)",
    inputErrorColor: "rgba(255, 102, 102, 1)",
  },

  sidebar: {
    projectsBg: "rgba(237, 237, 239, 1)",
    selectedItemBg: "rgba(19, 36, 65, 0.1)",
    requiredBg: "rgba(19, 36, 65, 0.05)",
    optionalBg: "rgba(42, 134, 255, 0.1)",
    tagBg: "rgba(42, 134, 255, 0.05)",
    linkBg: "rgba(42, 134, 255, 0.2)",
    elementExpandedBg: "rgba(171, 194, 224, 1)",
    selectedItemAccent: "rgba(19, 36, 65, 1)",
  },

  socialMediaPreviewCard: {
    buttonBg: "rgba(42, 134, 255, 0.03)",
    buttonBgSelected: "rgba(227, 237, 249, 1)",
    secondColor: "rgba(96, 103, 112, 1)",
    primaryColor: "rgba(29, 33, 41, 1)",
    xCardInfoBg: "rgba(242, 243, 245, 1)",
    xCardDomainColor: "rgba(181, 185, 190, 1)",
  },

  links: {
    tableCellColor: "rgba(19, 36, 65, 0.7)",
  },
};
